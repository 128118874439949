import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Hero from "../components/hero"


import "../styles/index.scss"
import layoutStyles from "../components/layout.module.scss"

const Layout = props => {
  
  return (
    <div className={layoutStyles.container}>
      
      <Header />
      <Hero
        title={props.title}
        btnText={props.btnText}
        btnLink={props.btnLink}
        backgroundImage={props.backgroundImage}
        
      />
      {props.children}
      <Footer />
    </div>
  )
}

export default Layout
