import React from "react"

import heroStyles from "./hero.module.scss"
import Button from "../components/button"

const Hero = props => {
  return (
    <section style={{ paddingTop: 0 }}>
      <wrapper
        className={heroStyles.hero}
        style={{
          backgroundImage: props.backgroundImage
            ? `url(${props.backgroundImage})`
            : undefined,
        }}
      >
        <h1 className={heroStyles.headline}>{props.title}</h1>
        {props.btnText && props.btnLink && (
          <Button text={props.btnText} btnLink={props.btnLink} />
        )}
      </wrapper>
    </section>
  )
}

export default Hero
