import React, {useState} from "react"
import { Link } from "gatsby"
import logo from "../../static/logo.svg"
import headerStyles from "./header.module.scss"
import { CgMenu } from "react-icons/cg";

const Header = () => {

  const [mobileNavOpened, setMobileNavOpened] = useState(false);
  const toggleMobileNav = () => setMobileNavOpened(!mobileNavOpened);
  
  return (
    <header>
      <nav role="navigation" className={mobileNavOpened ? headerStyles.dropdownOpened : ''}>
        <Link className={headerStyles.logo} to="/">
          <img src={logo} alt="Killer Click Logo" />
          
        </Link>
        <button className={headerStyles.mobileDropdownToggle} onClick={toggleMobileNav}>
          <CgMenu />
        </button>
        <div className={headerStyles.dropdownLinkContainer}>
          <ul>
            <li>
              <Link to="/" activeClassName={headerStyles.activeNavItem}>
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/google-marketing"
                activeClassName={headerStyles.activeNavItem}
              >
                Google Marketing
              </Link>
            </li>
            <li>
              <Link
                to="/web-development"
                activeClassName={headerStyles.activeNavItem}
              >
                Web Development
              </Link>
            </li>
            <li>
              <Link to="/blog" activeClassName={headerStyles.activeNavItem}>
                Blog
              </Link>
            </li>
            <li>
              <Link to="/contact" activeClassName={headerStyles.activeNavItem}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header
