import React from "react"
import {Link} from 'gatsby'
import buttonStyles from "./button.module.scss"

const Button = props => (
  <Link to={props.btnLink}>
    <button style={{color: props.color==='white'?'white':undefined}} className={buttonStyles.button}>{props.text}</button>
  </Link>
)

export default Button
